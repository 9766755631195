.nav{
    position: fixed;
    top: 0;
    padding: 20px;
    width: 100%;
    height: 30px;
    z-index: 1;

    /*Animation Transition */
    transition-timing-function: ease-in;
    transition: all 0.5s;
}
.nav_contents{
    display: flex;
    justify-content: space-between;
}

.nav_logo{
    position: fixed;
    top: 10px;
    left: 0px;
    width: 80px;
    object-fit: contain;
    padding-left: 20px;
    cursor: pointer;
    
}

.nav_avatar{
    position: fixed;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.nav_black{
    background-color: rgba(0, 0, 0, 0.9);
}
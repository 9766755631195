.banner{
    color: white;
    object-fit: contain;
    height: 448px;
}

.banner_contents{
    margin-left: 30px;
    padding-top: 140px;
    height: 190px;
}

.banner_title{
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
}

.banner_description{
    width: auto;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: 0.8rem;
    max-width: 360px;
    height: 80px;
}

.banner_button{
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 0.5rem;
}

.banner_button:hover{
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.2s;
}

.banner_fadeBottom{
    height: 7.4rem;
    background-image: linear-gradient(180deg,transparent,rgba(37,37,37,0.61),#111);
}
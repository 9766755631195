.row{
    margin-left: 20px;
    color: white;
}


.row_posters{
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
}

.row_poster{
    object-fit: contain;
    width: 100%;
    max-height: 100px;
    transition: transform 450ms;
    margin-right: 10px;
}

.row_posters::-webkit-scrollbar{
    display: none;
}

.row_poster:hover{
    transform: scale(1.08);
}

.row_posterLarge{
    max-height:250px;
}

.row_posterLarge:hover{
    transform: scale(1.09);
}
.row_trailer {
    position: relative;
    margin-top: 20px;
}

.row_trailerClose {
    position: absolute;
    top: -10px;
    right: 20px;
    background-color: rgba(51, 51, 51, 0.5);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 16px;
}

.row_trailerClose:hover {
    background-color: #e6e6e6;
}
